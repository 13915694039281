<template>
  <div>
    <div
      v-if="userData.searchType === 2 && !isEmpty(userData.searchType)"
    >
      <v-col>
        <v-simple-table
          dense
          class="commentTableCss"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  rowspan="2"
                  class="text-center"
                  style="background: #fff !important;width:110px;"
                >
                  Date
                </th>
                <th
                  rowspan="2"
                  class="text-center"
                  style="background: #ffff88 !important;width:100px;"
                >
                  Total
                </th>
                <th
                  colspan="2"
                  class="text-center"
                  style="background-color:#ffff88 !important;width:200px;"
                >
                  Last Year
                </th>
                <th
                  rowspan="2"
                  class="text-center"
                  style="background: #fff !important;width:150px;"
                >
                  Fixed Holiday
                </th>
                <th
                  rowspan="2"
                  class="text-center"
                  style="background: #fff !important;width:150px;"
                >
                  Special Holiday
                </th>
                <th
                  rowspan="2"
                  class="text-center"
                  style="background: #fff !important;width:160px;"
                >
                  Memo 1
                </th>
                <th
                  rowspan="2"
                  class="text-center"
                  style="background: #fff !important;width:160px;"
                >
                  Memo 2
                </th>
                <th
                  rowspan="2"
                  class="text-center"
                  style="background: #fff !important;width:160px;"
                >
                  Memo 3
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;width:100px;"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;width:100px;"
                >
                  Same<br>Week
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.commentTableData"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td :class="getLastYearSameDateClass(index)">
                  {{ item.count2 }}
                </td>
                <td :class="getLastYearSameWeekClass(index)">
                  {{ item.count3 }}
                </td>
                <td class="text-left">
                  {{ item.count4 }}
                </td>
                <td class="text-left">
                  {{ item.count5 }}
                </td>
                <td class="comment">
                  <div>
                    {{ item.count6 }}
                  </div>
                </td>
                <td class="comment">
                  <div>
                    {{ item.count7 }}
                  </div>
                </td>
                <td class="comment">
                  {{ item.count8 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.commentFooterData"
                :key="item.time"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
    </div>
    <!-- excel出力用 -->
    <div
      v-if="userData.searchType === 2 && !isEmpty(userData.searchType)"
      id="commentTable"
      hidden
      data-cols-width="25,10,10,10,20,20,20,20,20"
    >
      <v-col
        cols="12"
        md="12"
      >
        <table><tr></tr></table>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="2"
                >
                  Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  rowspan="2"
                >
                  Total
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  colspan="2"
                >
                  Last Year
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="2"
                >
                  Fixed Holiday
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="2"
                >
                  Special Holiday
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="2"
                >
                  Memo 1
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="2"
                >
                  Memo 2
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="2"
                >
                  Memo 3
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Week
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.commentTableData"
                :key="item.time"
                data-height="22"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.count2).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.count4 }}
                </td>
                <td
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.count5 }}
                </td>
                <td
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                >
                  <div>
                    {{ item.count6 }}
                  </div>
                </td>
                <td
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                >
                  <div>
                    {{ item.count7 }}
                  </div>
                </td>
                <td
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.count8 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.commentFooterData"
                :key="item.time"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  data-fill-color="ffff88"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  data-fill-color="ffff88"
                >
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  data-fill-color="ffff88"
                >
                  {{ String(item.count2).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  data-fill-color="ffff88"
                >
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ item.count4 }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ item.count5 }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ item.count6 }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ item.count7 }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ item.count8 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </div>
    <v-col>
      <v-btn
        v-if="userData.searchType === 2 && !loading"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateCommentXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </v-col>
    <div
      v-if="!isEmpty(userData.searchType)"
    >
      <v-col>
        <v-simple-table
          dense
          :style="userData.searchType === 1 ? 'width:928px': 'width:980px'"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-if="userData.searchType === 1"
                  class="text-center"
                  rowspan="2"
                >
                  Time
                </th>
                <th
                  v-if="userData.searchType === 2"
                  class="text-center"
                  rowspan="2"
                >
                  Date
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                  colspan="9"
                >
                  GF
                </th>
                <th
                  class="text-center"
                  style="background: #FFC000 !important"
                >
                  1F
                </th>
                <th
                  class="text-center"
                  style="background: #ccffcc !important"
                >
                  2F
                </th>
                <th
                  class="text-center"
                  style="background: #64fcfc !important"
                  colspan="4"
                >
                  3F
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  Ent A
                </th>
                <th class="text-center">
                  Ent B1
                </th>
                <th class="text-center">
                  Ent B2
                </th>
                <th class="text-center">
                  Ent C1
                </th>
                <th class="text-center">
                  Ent C2
                </th>
                <th class="text-center">
                  Ent D
                </th>
                <th class="text-center">
                  Ent K
                </th>
                <th class="text-center">
                  Ent L1
                </th>
                <th class="text-center">
                  Ent L2
                </th>
                <th class="text-center">
                  Ent P2
                </th>
                <th class="text-center">
                  Ent P4
                </th>
                <th class="text-center">
                  Ent H1
                </th>
                <th class="text-center">
                  Ent H2
                </th>
                <th class="text-center">
                  Ent H3
                </th>
                <th class="text-center">
                  Ent P6
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td
                  class="text-center"
                  :style="userData.searchType === 2 ? 'width:110px;':''"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table1FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <br>
        <v-simple-table
          dense
          :style="userData.searchType === 1 ? 'width:928px': 'width:980px'"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-center"
                  rowspan="2"
                >
                </th>
                <th
                  class="text-center"
                  style="background: #ffff99 !important"
                  colspan="3"
                >
                  RF
                </th>
                <th
                  class="text-center"
                  style="background: #ccccff !important"
                  colspan="8"
                >
                  BF
                </th>
                <th
                  class="text-center"
                  style="background: #ffff88 !important;"
                  rowspan="2"
                >
                  Total
                </th>
                <th
                  class="text-center"
                  style="background: #ffff88 !important;"
                  rowspan="2"
                >
                  Cum<br>Total
                </th>
                <th
                  class="text-center"
                  style="background: #ffff88 !important;"
                  colspan="2"
                >
                  Last Year
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  Ent Q
                </th>
                <th class="text-center">
                  Ent R1
                </th>
                <th class="text-center">
                  Ent R2
                </th>
                <th class="text-center">
                  Ent E1
                </th>
                <th class="text-center">
                  Ent E2
                </th>
                <th class="text-center">
                  Ent F
                </th>
                <th class="text-center">
                  Ent G1
                </th>
                <th class="text-center">
                  Ent G2
                </th>
                <th class="text-center">
                  Ent M
                </th>
                <th class="text-center">
                  Ent N1
                </th>
                <th class="text-center">
                  Ent N2
                </th>
                <th
                  class="text-center"
                  style="background: #ffff88 !important"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background: #ffff88 !important"
                >
                  Same<br>Week
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table2Data"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td
                  class="text-center"
                  :style="userData.searchType === 2 ? 'width:110px;':''"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td
                  v-if="userData.searchType === 1"
                  class="text-right"
                >
                  {{ item.count14 }}
                </td>
                <td
                  v-if="userData.searchType === 1"
                  class="text-right"
                >
                  {{ item.count15 }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  :class="getLastYearSameDateClass(index)"
                >
                  {{ item.count14 }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  :class="getLastYearSameWeekClass(index)"
                >
                  {{ item.count15 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table2FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
      <br>
    </div>
    <!-- excel出力用 -->
    <div
      id="mainTable"
      hidden
      :data-cols-width="userData.searchType === 2?'20,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10':'10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10'"
    >
      <v-col
        cols="12"
        md="12"
      >
        <table><tr></tr></table>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  rowspan="2"
                >
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                  colspan="9"
                  data-fill-color="ffbbff"
                >
                  GF
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                  data-fill-color="FFC000">
                  1F
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                  data-fill-color="ccffcc">
                  2F
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                  colspan="4"
                  data-fill-color="64fcfc">
                  3F
                </th>
              </tr>
              <tr>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent A
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent B1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent B2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent C1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent C2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent D
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent K
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent L1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent L2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent P2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent P4
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent H1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent H2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent H3
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent P6
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="item.time"
                :data-fill-color="getColor(item.time, index)"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ Number(String(item.count2).replace(',','').replace(',','')) }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count4).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count5).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count6).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count7).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count8).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count9).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count10).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count11).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count12).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count13).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count14).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count15).replace(',','').replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table1FooterData"
                :key="item.time"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count1).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count1).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count2).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count2).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count3).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count4).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count5).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count5).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count6).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count6).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count7).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count8).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count9 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count9).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count10 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count10).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count11 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count11).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count12 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count12).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count13 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count13).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count14 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count14).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count14).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count15 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count15).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count15).replace('%',''))/100 }}
                  </p>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
      <table><tr></tr></table>
      <v-col
        cols="12"
        md="8"
      >
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-b-a-s="thin"
                  class="text-center"
                  data-a-h="center"
                  rowspan="2"
                >
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                  colspan="3"
                  data-fill-color="ffff99">
                  RF
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                  colspan="8"
                  data-fill-color="ccccff">
                  BF
                </th>
                <th
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-a-h="center"
                  class="text-center"
                  rowspan="2"
                >
                  Total
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  data-fill-color="ffff88"
                  class="text-center"
                  rowspan="2"
                >
                  Cum Total
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  data-fill-color="ffff88"
                  class="text-center"
                  colspan="2"
                >
                  Last Year
                </th>
              </tr>
              <tr>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent Q
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent R1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent R2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent E1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent E2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent F
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent G1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent G2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent M
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent N1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent N2
                </th>
                <th
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-a-h="center"
                  class="text-center"
                >
                  Same Date
                </th>
                <th
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-a-h="center"
                  class="text-center"
                >
                  Same Week
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table2Data"
                :key="item.time"
                :data-fill-color="getColor(item.time, index)"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ Number(String(item.count2).replace(',','').replace(',','')) }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count4).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count5).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count6).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count7).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count8).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count9).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count10).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count11).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count12).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count13).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="userData.searchType === 1"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count14).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.count14).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="userData.searchType === 1"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count15).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.count15).replace(',','').replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table2FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count1).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count1).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count2).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count2).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count3).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count4).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count5).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count5).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count6).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count6).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count7).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count8).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count9 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count9).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count10 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count10).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count11 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count11).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                 :data-t="index == 0 ? 'n':'s'"
                  :data-num-fmt="index == 0 ? (item.count12 === '0'?'0':'###,###'):''"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    -
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                 :data-t="index == 0 ? 'n':'s'"
                  :data-num-fmt="index == 0 ? (item.count13 === '0'?'0':'###,###'):''"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    -
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                 :data-t="index == 0 ? 'n':'s'"
                  :data-num-fmt="index == 0 ? (item.count14 === '0'?'0':'###,###'):''"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count14).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    -
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                 :data-t="index == 0 ? 'n':'s'"
                  :data-num-fmt="index == 0 ? (item.count15 === '0'?'0':'###,###'):''"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count15).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    -
                  </p>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
    </div>
    <v-col>
      <v-btn
        v-if="!loading"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </v-col>
    <br>
    <div v-if="!isEmpty(userData.searchType) && userData.searchType === 2">
      <v-col>
        <v-simple-table
          dense
          style="width:1178px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Date
                </th>
                <th class="text-center">
                  8-9
                </th>
                <th class="text-center">
                  9-10
                </th>
                <th class="text-center">
                  10-11
                </th>
                <th class="text-center">
                  11-12
                </th>
                <th class="text-center">
                  12-13
                </th>
                <th class="text-center">
                  13-14
                </th>
                <th class="text-center">
                  14-15
                </th>
                <th class="text-center">
                  15-16
                </th>
                <th class="text-center">
                  16-17
                </th>
                <th class="text-center">
                  17-18
                </th>
                <th class="text-center">
                  18-19
                </th>
                <th class="text-center">
                  19-20
                </th>
                <th class="text-center">
                  20-21
                </th>
                <th class="text-center">
                  21-22
                </th>
                <th class="text-center">
                  22-23
                </th>
                <th class="text-center">
                  23-00
                </th>
                <th class="text-center" style="width:70px;background: #ffff88 !important;">
                  Total
                </th>
                <th class="text-center" style="width:70px;background: #ffff88 !important;">
                  Cum Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table3Data"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td
                  class="text-center"
                  style="width:110px;"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
                <td class="text-right">
                  {{ item.count16 }}
                </td>
                <td class="text-right">
                  {{ item.count17 }}
                </td>
                <td
                  class="text-right"
                >
                  {{ item.count18 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table3FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
                <td class="text-right">
                  {{ item.count16 }}
                </td>
                <td class="text-right">
                  {{ item.count17 }}
                </td>
                <td class="text-right">
                  {{ item.count18 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
    </div>
    <!-- excel出力用 -->
    <div
      v-if="!isEmpty(userData.searchType) && userData.searchType === 2"
      id="timeTable"
      hidden
      data-cols-width="25,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10"
    >
      <v-col
        cols="12"
        md="12"
      >
        <table><tr></tr></table>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  8-9
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  9-10
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  10-11
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  11-12
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  12-13
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  13-14
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  14-15
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  15-16
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  16-17
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  17-18
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  18-19
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  19-20
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  20-21
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  21-22
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  22-23
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  23-00
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Total
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Cum Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table3Data"
                :key="item.time"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ Number(String(item.count2).replace(',','').replace(',','')) }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count4).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count5).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count6).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count7).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count8).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count9).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count10).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count11).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count12).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count13).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count14).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count15).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count16 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count16).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count17 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count17).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count18 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count18).replace(',','').replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table3FooterData"
                :key="item.time"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count1).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count1).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count2).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count2).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count3).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count4).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count5).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count5).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count6).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count6).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count7).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count8).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count9 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count9).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count10 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count10).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count11 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count11).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count12 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count12).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count13 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count13).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count14 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count14).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count14).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count15 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count15).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count15).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="index == 0 ? (item.count16 === '0'?'0':'###,###'):'0.0%'"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  class="text-right"
                >
                  <p v-if="index == 0">
                    {{ String(item.count16).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count16).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  :data-t="index == 0 ? 'n':'s'"
                  :data-num-fmt="index == 0 ? (item.count17 === '0'?'0':'###,###'):''"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count17).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    -
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  :data-t="index == 0 ? 'n':'s'"
                  :data-num-fmt="index == 0 ? (item.count18 === '0'?'0':'###,###'):''"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  <p v-if="index == 0">
                    {{ String(item.count18).replace(',','').replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    -
                  </p>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
    </div>
    <v-col>
      <v-btn
        v-if="userData.searchType === 2 && !loading"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateTimeXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </v-col>
  </div>
</template>
<script>
import { isEmpty, date2yyyymmddhhmmss } from '@core/utils'
import { mdiDownloadOutline } from '@mdi/js'
import tableToExcelJs from '@linways/table-to-excel'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      require: true,
    },
  },
  methods: {
    getLastYearSameDateClass(index) {
      if (this.userData.searchType === 1) return ''
      let css = 'Normal text-right'
      const time = this.userData.lastLegendData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun text-right'
      }

      return css
    },

    getLastYearSameWeekClass(index) {
      if (this.userData.searchType === 1) return ''
      let css = 'Normal text-right'
      const time = this.userData.lastYearSameWeekData[index]
      if (time.indexOf('Sat') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun text-right'
      } else if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      }

      return css
    },

    getClass(time, index) {
      let css = ''

      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          css = ''
        } else {
          css = 'Gray'
        }

        return css
      }

      if (time.indexOf('Sat') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun'
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        css = 'Holiday'
      }

      return css
    },
    getColor(time, index) {
      let color = ''
      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          color = ''
        } else {
          color = 'c0c0c0'
        }
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else {
        color = ''
      }

      return color
    },
    getSameDataColor(index) {
      let color = ''
      if (this.userData.searchType === 1) return color

      const time = this.userData.lastLegendData[index]
      if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      } else if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      }

      return color
    },
    getSameWeekColor(index) {
      let color = ''
      if (this.userData.searchType === 1) return color

      const time = this.userData.lastYearSameWeekData[index]
      if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      } else if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      }

      return color
    },

    /* excelダウンロード */
    generateXLSX: () => {
      const div = document.getElementById('mainTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },

    generateCommentXLSX: () => {
      const div = document.getElementById('commentTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },

    generateTimeXLSX: () => {
      const div = document.getElementById('timeTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },
  },
  setup() {
    return {
      isEmpty,
      icons: {
        mdiDownloadOutline,
      },
    }
  },
}
</script>

<style scoped>
.tableCss3 th {
  padding: 0px 5px !important;
  /* background: #ccffcc !important; */
}

.v-data-table tbody .Normal {
  background-color: #fff;
}

.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}

.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}
.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}

.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000 !important;
  padding: 0px 1px !important;
  font-size: 12px !important;
  width: 58px;
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  white-space: nowrap;
  width: 58px;
  height: 14px;
}

.summary-Table.v-data-table th,
td {
  border: none !important;
}

.commentTableCss {
  width: 1190px;
}
.commentTableCss th {
  padding: 0px 5px !important;
  font-size: 12px !important;
}
.commentTableCss td {
  padding: 0px 5px !important;
  font-size: 12px !important;
}

.commentTableCss tfoot tr {
  background: #ffff88 !important;
}
table tr td.comment div {
  width: 160px;
  height: 100%;
  overflow-y: auto;
}
table tr td.comment {
  padding-left: 1px !important;
  text-align: left !important;
  padding: 0 !important;
  margin: 0 !important;
  white-space: initial !important;
  min-width: initial !important;
}
</style>
