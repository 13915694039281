<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div v-if="userData.searchType === 1">
      <br>
      <template>
        <v-data-table
          style="width:800px;"
          :headers="userData.summaryHeaders"
          :items="userData.summaryData"
          disable-sort
          :hide-default-footer="true"
          class="summary-Table"
        >
          <template v-slot:item.count="{ item }">
            <span>{{ String(item.count).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}</span>
          </template>
        </v-data-table>
      </template>
    </div>
    <br>

    <div id="chart">
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '400px'}"
      ></div>
      <div
        class="d-flex align-left flex-wrap"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-model="countDisplayswitch"
          flat
          :label="countDisplayswitch ? `Count Display`:`Count Undisplayed`"
          hide-details
          @change="countDisplay"
        ></v-switch>
      </div>
      <div
        class="d-flex align-left flex-wrap"
        v-if="userData.searchType !== 2"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-model="top5Displayswitch"
          flat
          :label="top5Displayswitch ? `All Display`:`Top5 + Others Display`"
          hide-details
          @change="top5Display"
        ></v-switch>
      </div>
      <div
        ref="pieChart"
        :style="{ width: '100%', height: '500px'}"
      ></div>
      <div
        class="d-flex align-left flex-wrap"
        v-if="!isEmpty(userData.top5PieData)"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:330px"
          v-model="top5PieDisplayswitch"
          flat
          :label="top5PieDisplayswitch ? `All Display`:`Top5 + Others Display`"
          hide-details
          @change="top5PieDisplay"
        ></v-switch>
      </div>
    </div>
    <br>
    <floorplan
      v-if="userData.image_src !== undefined"
      id="exportMapDiv"
      :img-src="require(`@/assets${userData.image_src}`)"
      :image-width="userData.image_witdh"
      :image-height="userData.image_height"
      :entrance-data="userData.entranceData"
    >
    </floorplan>
    <br>
    <div>
      <v-btn
        icon
        small
        @click="exportMaptoImage"
      >
        <v-icon
          dark
          right
        >
          {{ icons.mdiArrowCollapseDown }}
        </v-icon>
      </v-btn>
    </div>
    <br>

    <le-chan-table
      v-if="siteId === '16' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></le-chan-table>
    <tan-phu-table
      v-if="siteId === '12' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></tan-phu-table>
    <ha-dong-table
      v-if="siteId === '15' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></ha-dong-table>
    <binh-tan-table
      v-if="siteId === '11' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></binh-tan-table>
    <long-bien-table
      v-if="siteId === '8' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></long-bien-table>
    <binh-duong-table
      v-if="siteId === '3' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></binh-duong-table>
    <phnom-penh-table
      v-if="siteId === '2' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></phnom-penh-table>
    <taman-maluri-table
      v-if="siteId === '14' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></taman-maluri-table>
  </div>
</template>

<script>
import { mdiDownloadOutline, mdiArrowCollapseDown } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import store from '@/store'
import entranceStoreModule from './entranceStoreModule'
import html2canvas from 'html2canvas'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import floorplan from '@/views/components/blueprint/FloorPlan.vue'
import eventBus from '@core/utils/eventBus'
import Loading from '@/views/components/load/Loading.vue'
import LeChanTable from './LeChan/LeChanTable.vue'
import TanPhuTable from './TanPhu/TanPhuTable.vue'
import HaDongTable from './HaDong/HaDongTable.vue'
import BinhTanTable from './BinhTan/BinhTanTable.vue'
import LongBienTable from './LongBien/LongBienTable.vue'
import BinhDuongTable from './BinhDuong/BinhDuongTable.vue'
import PhnomPenhTable from './PhnomPenh/PhnomPenhTable.vue'
import TamanMaluriTable from './TamanMaluri/TamanMaluriTable.vue'

const USER_APP_STORE_MODULE_NAME = 'app-entrance'

const userData = ref([])
const currentDate = new Date()
const echarts = require('echarts')
const moment = require('moment')

export default {
  components: {
    floorplan,
    Loading,
    LeChanTable,
    TanPhuTable,
    HaDongTable,
    BinhTanTable,
    LongBienTable,
    BinhDuongTable,
    PhnomPenhTable,
    TamanMaluriTable,
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
        this.drawPie(val)
      }, 500)
    },
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.toggleExclusive = ref(undefined)
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = true
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false

    this.$datePickerDisplayFlag = false
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      this.loading = true
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const siteId = String(getSessionData('data').siteId)
      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted

      const sDate = moment().subtract(1, 'year').startOf('year').toDate()
      const eDate = moment().subtract(1, 'year').endOf('year').toDate()
      const sDateFormatted = `${sDate.getFullYear()}
      -${(sDate.getMonth() + 1).toString().padStart(2, '0')}
      -${sDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const eDateFormatted = `${eDate.getFullYear()}
      -${(eDate.getMonth() + 1).toString().padStart(2, '0')}
      -${eDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      let paramId = ''
      if (startDateFormatted === sDateFormatted && endDateFormatted === eDateFormatted) {
        paramId = '3'
      } else if (startDateFormatted === endDateFormatted) {
        paramId = '2'
      } else {
        paramId = '1'
      }
      console.log(paramId)

      store
        .dispatch('app-entrance/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          this.loading = false
          userData.value.labelShow = this.countDisplayswitch
          if (userData.value.searchType === 2) {
            userData.value.top5Show = true
          } else {
            userData.value.top5Show = this.top5Displayswitch
          }

          userData.value.top5PieShow = this.top5PieDisplayswitch
        })
        .catch(error => {
          // ログイン画面に遷移する
          console.log(error)
          this.loading = false

          this.$router.push({ name: 'error-login' })
        })
    },
    countDisplay() {
      this.userData.labelShow = this.countDisplayswitch
      this.drawLine(this.userData)
    },
    top5Display() {
      this.userData.top5Show = this.top5Displayswitch
      this.drawLine(this.userData)
    },

    top5PieDisplay() {
      this.userData.top5PieShow = this.top5PieDisplayswitch
      this.drawPie(this.userData)
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData = []

      if (val.top5Show) {
        for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
          const element = Object.values(val.seriesData)[i]

          const newData = {
            name: element.name,
            type: 'line',
            label: {
              normal: {
                show: val.labelShow,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
            data: element.data,
          }
          newSeriesData.push(newData)
        }
      } else {
        for (let i = 0; i < Object.values(val.top5SeriesData).length; i += 1) {
          const element = Object.values(val.top5SeriesData)[i]

          const newData = {
            name: element.name,
            type: 'line',
            label: {
              normal: {
                show: val.labelShow,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
            data: element.data,
          }
          newSeriesData.push(newData)
        }
      }

      const lineChartOption = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: '0',
          left: '5%',
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '75%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '0%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData,
        },
        yAxis: {},
        series: newSeriesData,
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },

    // pie chart作成する
    drawPie(val) {
      let pieChartOption

      if (!val.top5PieShow) {
        const percentageMap = new Map()
        if (!isEmpty(val.top5PieData)) {
          val.top5PieData.forEach(element => {
            percentageMap.set(element.name, ((element.value / val.totalData) * 100).toFixed(1))
          })
        }

        pieChartOption = {
          toolbox: {
            bottom: 100,
            left: '5%',
            feature: {
              saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: params => {
              const v = parseFloat(params.value)
              const value = String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')

              return `${params.name}: ${value} (${params.percent.toFixed(1)}%)`
            },
          },
          legend: {
            orient: 'vertical',
            right: 200,
            top: 50,
            bottom: 100,
            formatter: name => {
              const rate = percentageMap.get(name)

              return `${name} ${rate}%`
            },
          },
          color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
          series: [
            {
              // name: 'People Count',
              type: 'pie',
              radius: ['30%', '80%'],
              avoidLabelOverlap: false,
              center: ['40%', '50%'],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  textStyle: {
                    fontWeight: 300,
                    fontSize: 12,
                    color: 'black',
                  },
                  formatter: a => `${a.name}\n${a.percent.toFixed(1)}%`,
                },
              },
              labelLine: {
                show: false,
              },
              data: val.top5PieData,
            },
          ],
        }
      } else {
        const percentageMap = new Map()
        if (!isEmpty(val.pieData)) {
          val.pieData.forEach(element => {
            percentageMap.set(element.name, ((element.value / val.totalData) * 100).toFixed(1))
          })
        }

        pieChartOption = {
          toolbox: {
            bottom: 100,
            left: '5%',
            feature: {
              saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: params => {
              const v = parseFloat(params.value)
              const value = String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')

              return `${params.name}: ${value} (${params.percent.toFixed(1)}%)`
            },
          },
          legend: {
            orient: 'vertical',
            right: 200,
            top: 50,
            bottom: 100,
            formatter: name => {
              const rate = percentageMap.get(name)

              return `${name} ${rate}%`
            },
          },
          color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
          series: [
            {
              // name: 'People Count',
              type: 'pie',
              radius: ['30%', '80%'],
              avoidLabelOverlap: false,
              center: ['40%', '50%'],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  textStyle: {
                    fontWeight: 300,
                    fontSize: 12,
                    color: 'black',
                  },
                  formatter: a => {
                    if (val.searchType === 3) {
                      return `${a.name}\n${a.percent.toFixed(1)}%`
                    }
                    if (val.max80Data.indexOf(a.name) >= 0) {
                      return `${a.name}\n${a.percent.toFixed(1)}%`
                    }

                    return ''
                  },
                },
              },
              labelLine: {
                show: false,
              },
              data: val.pieData,
            },
          ],
        }
      }

      let pieChart = echarts.getInstanceByDom(this.$refs.pieChart)
      if (pieChart == null) {
        pieChart = echarts.init(this.$refs.pieChart)
      }

      pieChart.setOption(pieChartOption, true)
      if (!isEmpty(val.pieData)) {
        this.$refs.pieChart.style.display = 'block'
      } else {
        this.$refs.pieChart.style.display = 'none'
      }
    },

    /* map印刷 */
    exportMaptoImage: () => {
      html2canvas(document.querySelector('#exportMapDiv')).then(canvas => {
        const downloadEle = document.createElement('a')
        downloadEle.href = canvas.toDataURL('image/png')
        downloadEle.download = `${date2yyyymmddhhmmss(new Date())}.jpeg`
        downloadEle.click()
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, entranceStoreModule)
    }
    const startDate = sessionStorage.getItem('startDate')
    if (isEmpty(startDate)) {
      const startDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      sessionStorage.setItem('startDate', startDateFormatted)
      sessionStorage.setItem('endDate', endDateFormatted)
    }

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const countDisplayswitch = ref(true)

    const top5Displayswitch = ref(true)

    const top5PieDisplayswitch = ref(true)

    return {
      top5PieDisplayswitch,
      top5Displayswitch,
      countDisplayswitch,
      siteId: String(getSessionData('data').siteId),
      userData,
      icons: {
        mdiDownloadOutline,
        mdiArrowCollapseDown,
      },
      loading: false,
      isEmpty,
    }
  },
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
</style>

<style scoped>
.tableCss3 th {
  padding: 0px 5px !important;
  /* background: #ccffcc !important; */
}

.v-data-table tbody .Normal {
  background-color: #fff;
}

.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}

.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}
.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}

.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000 !important;
  padding: 0px 1px !important;
  font-size: 12px !important;
  width: 58px;
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  white-space: nowrap;
  width: 58px;
}

.summary-Table.v-data-table th,
td {
  border: none !important;
}

.commentTableCss {
  width: 1190px;
}
.commentTableCss th {
  padding: 0px 5px !important;
  font-size: 12px !important;
}
.commentTableCss td {
  padding: 0px 5px !important;
  font-size: 12px !important;
}

.commentTableCss tfoot tr {
  background: #ffff88 !important;
}
table tr td.comment div {
  width: 160px;
  height: 100%;
  overflow-y: auto;
}
table tr td.comment {
  padding-left: 1px !important;
  text-align: left !important;
  padding: 0 !important;
  margin: 0 !important;
  white-space: initial !important;
  min-width: initial !important;
}
</style>
